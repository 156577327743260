<script>
import {mapGetters} from "vuex";
import {GameStatus, GameType, getRouteNameByGameType} from "../../../gameCommon";
import {Modal as _BSModal} from "bootstrap";

export default {
  name: 'TournamentInfoLayer',
  components:{
    TournamentInfoLayerContent: () => import(/* webpackChunkName: "tournament-info-layer-content-v2" */ "@/components/Game/Modal/TournamentInfoLayerContent.vue"),
  },
  props:{
    layerType:{
      type: String,
      required:true,
      enum:['room', 'lobby'],
    },
    tournamentInfo: {
      type: Object,
      required: true,
    },
    userSn:{
      type: Number,
      required: true,
    },
    roomCode:{
      type: Number,
      required: false,
    },
    roomUri:{
      type: String,
      required: false,
    },
  },
  data() {
    return {
      displayInfoType: 'room',
      modal: undefined,
    }
  },
  computed:{
    ...mapGetters(['token']),
    GameStatus() {
      return GameStatus
    },
    roomTitle(){
      return this.tournamentInfo.room?.title || '';
    },
    isLobby(){
      return this.layerType === 'lobby';
    },
    isRoom(){
      return this.layerType === 'room';
    }
  },
  methods: {
    isStatus( status ){
      return this.tournamentInfo.room?.gameStatus === status
    },
    isDisplay( type ){
      return this.displayInfoType === type;
    },
    changeDisplay( type ){
      this.displayInfoType = type;
    },
    getTournamentRoomUrl(){
      const path = getRouteNameByGameType(GameType.Tournament);
      return `${this.roomUri}/${path}`;
    },
    joinTournament() {
      this.$emit('join', {
        token: this.token,
        roomCode: this.roomCode,
        data: {
          roomUrl: this.getTournamentRoomUrl(),
          type: GameType.Tournament,
        }
      });
    },
  },
  mounted() {
    this.modal = _BSModal.getOrCreateInstance($(this.$refs.root));
  },
  beforeDestroy() {
    this.modal?.hide();
  }
}
</script>

<template>
  <!-- 정보 레이어팝업 -->
  <div v-if="isLobby" ref="root" class="modal fade basic_modal wrap_tn_info" id="layerpopup_info" tabindex="-1"  aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content tn_info_lobby">
        <div class="modal-header">
          <h1 class="modal-title tn_tit">{{ roomTitle }}</h1>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <div class="info_header">
            <div class="wrap_tab">
              <div class="tab_item" :class="{active:isDisplay('room')}" @click.stop.prevent="changeDisplay('room')"><button type="button">정보</button></div>
              <div class="tab_item" :class="{active:isDisplay('rank')}" @click.stop.prevent="changeDisplay('rank')"><button type="button">순위</button></div>
              <div class="tab_item" :class="{active:isDisplay('prize')}"><button type="button" @click.stop.prevent="changeDisplay('prize')">상금</button></div>
              <div class="tab_item" :class="{active:isDisplay('blind')}"><button type="button" @click.stop.prevent="changeDisplay('blind')">블라인드</button></div>
            </div>
          </div>
        </div>
        <TournamentInfoLayerContent v-if="isLobby" :tournamentInfo="tournamentInfo" :userSn="userSn" :displayInfoType="displayInfoType" />
        <div class="modal-footer" v-show="isStatus(GameStatus.Ready) || isStatus(GameStatus.Playing)">
          <button type="button" @click.prevent.stop="joinTournament" class="btn btn-lg btn-warning w-100">입장하기</button>
        </div>
      </div>
    </div>
  </div>
  <div v-else-if="isRoom" ref="root" class="layerpopup_info wrap_tn_info offcanvas offcanvas-bottom" data-bs-scroll="true" data-bs-backdrop="false" tabindex="-1" id="layerpopup_info">
    <div class="info_header">
      <div class="area_tit">
        <div class="tit">{{ roomTitle }}</div>
      </div>
      <div class="wrap_tab">
        <div class="tab_item" :class="{active:isDisplay('room')}" @click.stop.prevent="changeDisplay('room')"><button type="button">정보</button></div>
        <div class="tab_item" :class="{active:isDisplay('rank')}" @click.stop.prevent="changeDisplay('rank')"><button type="button">순위</button></div>
        <div class="tab_item" :class="{active:isDisplay('prize')}"><button type="button" @click.stop.prevent="changeDisplay('prize')">상금</button></div>
        <div class="tab_item" :class="{active:isDisplay('blind')}"><button type="button" @click.stop.prevent="changeDisplay('blind')">블라인드</button></div>
      </div>
      <button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
    </div>
    <TournamentInfoLayerContent v-if="isRoom" :tournamentInfo="tournamentInfo" :userSn="userSn" :displayInfoType="displayInfoType" />
  </div>
  <!-- //정보 레이어팝업 -->
</template>